var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-1 wrapper1"},[_c('div',{staticClass:"d-flex flex-column px-1 wrapper2"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.nameArr.length > 0)?_c('div',{staticClass:"white--text"},_vm._l((_vm.nameArr),function(nameLine,index){return _c('span',{key:`text-${index}`,staticClass:"minecraft-text",style:(`
					color: ${nameLine.color};
					text-decoration: ${nameLine.underlined ? 'underline' : ''} ${nameLine.strikethrough ? 'line-through' : ''};
					${nameLine.bold ? 'text-shadow: 1px 0px;' : ''}
					${nameLine.italic ? 'font-style: italic;' : ''}
					`)},[_vm._v(_vm._s(nameLine.text))])}),0):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.item.name))]),_c('v-spacer'),_c('img',{staticClass:"ml-5",attrs:{"src":this.getItemIcon()}}),(_vm.item.count)?_c('span',{style:(_vm.item.count > 0 && _vm.item.count < 65 ? `color: #FFF` : 'color: #F55'),attrs:{"id":"item-count"}},[_vm._v(_vm._s(_vm.item.count))]):_vm._e()],1),_c('div',_vm._l((_vm.loreArr),function(loreLine,index){return _c('span',{key:`lore-${index}`,staticClass:"minecraft-text",style:(`
				color: ${loreLine.color ? loreLine.color : '#AA00AA'};
				${loreLine.color ? '' : 'font-style: italic;'}
				text-decoration: ${loreLine.underlined ? 'underline' : ''} ${loreLine.strikethrough ? 'line-through' : ''};
				${loreLine.bold ? 'text-shadow: 1px 0px;' : ''}
				${loreLine.italic ? 'font-style: italic;' : ''}
				`)},[_vm._v(_vm._s(loreLine.text)),(loreLine.break)?_c('br'):_vm._e()])}),0),(_vm.showEnchantments)?_c('div',_vm._l((_vm.enchantments.filter(enchantment => enchantment.level !== 0)),function(enchantment){return _c('span',{key:enchantment.name,staticStyle:{"color":"#AAA"}},[_vm._v(_vm._s(enchantment.name)+" "+_vm._s(_vm.convertToRoman(enchantment.level))),_c('br')])}),0):_vm._e(),(_vm.showUnbreakable)?_c('span',{staticStyle:{"color":"#55F"}},[_vm._v("Unbreakable")]):_vm._e(),_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v("minecraft:"+_vm._s(this.item.text_type))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }