import { render, staticRenderFns } from "./TrailerPage.vue?vue&type=template&id=29118f01"
import script from "./TrailerPage.vue?vue&type=script&lang=js"
export * from "./TrailerPage.vue?vue&type=script&lang=js"
import style0 from "./TrailerPage.vue?vue&type=style&index=0&id=29118f01&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports